import React from 'react';
import Header from '../componentes/Header';
import './Landing.css';
import Tecnologia from '../componentes/Tecnologia';
import Experiencias from '../componentes/Experiencias';
import { Helmet } from 'react-helmet-async';
import imagen from '../assets/1516768789422.jpg';

const SITE_NAME = 'STARKING';

export default function Inicio() {
	return (
		<>
			<Helmet defer={false}>
				<title>Starking Miguel De La Cruz Reyes | Desarrollador Web</title>
				<meta
					name="description"
					content="Desarrollador de web senior, tecnologías: HTML5, CSS3, Javascript, Jquery, PHP, C#, React, SQLserver, MySQL, Git, GitHub y Mas"
				/>
				<meta property="og:title" content="Starking Miguel | Desarrollador Web" />
				<meta property="og:type" content="website" />
				<meta property="og:url" content={process.env.REACT_APP_URL} />
				<meta property="og:image" content={`${process.env.REACT_APP_URL}${imagen}`} />
			</Helmet>
			<Header
				titulo={SITE_NAME}
				fondo="https://www.techyon.es/media/news/web-developer-principales-responsabilidades-y-competencias_1650467356_100.jpg"
			/>
			<section id="contacto" className="landing" style={{ padding: '40px 0' }}>
				<div className="main">
					<div className="mb-20">
						<h2 className="t2 text-right">📞 Contactos</h2>
					</div>
					<div>
						<ul>
							<li>Soy Starking Miguel De la Cruz Reyes</li>
							<li>Nací en Santo Domingo, Republica Dominicana, 29 de octubre 1993</li>
							<li>Vivo en Santo Domingo, Distrito Nacional</li>
							<li>
								<a href="mailto://starkingize@gmail.com">starkingize@gmail.com</a>
							</li>
							<li>+1(849)852-4190</li>
						</ul>
					</div>
				</div>
			</section>
			<section id="acerca" className="landing" style={{ padding: '40px 0' }}>
				<div className="main">
					<div className="mb-20">
						<h2 className="t2 text-right">🤯 Saber mas</h2>
					</div>
					<div>
						<p>
							Desarrollador de sistemas desde el 2010 con experiencia en desarrollos básicos y muy avanzados, algunos de estos fueron
							desplegados a nivel nacional e internacional en los cuales estuve participando en el análisis y creación en áreas tan
							distintas como el entretenimiento y la música a sistemas clínicos.
						</p>
						<br />
						<p>
							Desarrollo de proyectos freelancer, maquetación completa del frontend, análisis y creación del backend, con metodologías de
							desarrollo más recomendadas de utilizar en su momento y de esa manera asegurar la escalabilidad del sistema.
						</p>
						<br />
						<p>
							También cuento con conocimientos de social media, marketing digital, conocimiento de integración de herramientas de terceros
							que permitan interoperatividad entre sistemas.
						</p>
						<br />
						<p>Les diría mas pero seria mejor que me contacten. 😎</p>
					</div>
				</div>
			</section>
			<section className="landing" style={{ padding: '40px 0' }}>
				<div className="main">
					<div className="mb-20">
						<h2 className="t2 text-right">⛹️‍♀️ Habilidades</h2>
					</div>
					<div>
						<ul>
							<li>Desarrollo Web Frontend</li>
							<li>Desarrollo Web Backend</li>
							<li>Diseño y Maquetación Web</li>
							<li>Análisis de Sistemas</li>
							<li>UI - UX</li>
							<li>SEO on Page | Seo Out Page</li>
							<li>Manejo Servidores Linux y Windows</li>
							<li>AWS Amazon Web Services</li>
							<li>Autodidacta</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="landing" style={{ padding: '40px 0' }}>
				<div className="main">
					<div className="mb-20">
						<h2 className="t2 text-right">👨‍💻 Tecnologías</h2>
					</div>
					<Tecnologia />
				</div>
			</section>
			<section className="landing" style={{ padding: '40px 0' }}>
				<div className="main">
					<div className="mb-20">
						<h2 className="t2 text-right">♟ Experiencia profesional</h2>
					</div>
					<Experiencias />
				</div>
			</section>
		</>
	);
}
